import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useUserList() {
    const isUserFormSidebarActive = ref(false)
    const toast = useToast()
    const refUserListTable = ref(null);
    const tableColumns = [
      
        { key: 'first_name', label: 'User', sortable: false },
        { key: 'email', label: 'Email', sortable: false },
        { key: 'group_id', label: 'Group', sortable: false },
        { key: 'is_active', label: 'Status', sortable: false },
        { key: 'action', label: '#', sortable: false },


    ]
    const perPage = ref(10)
    const totalGroups = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)
    const userda = ref(null);
    
    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalGroups.value,
        }
    })
    const refetchData = () => {
        refUserListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery, statusFilter], () => {
        refetchData()
    })
    const fetchData = (ctx, callback) => {
        const offset = perPage.value*(currentPage.value-1);
        console.log('offset',offset);
        store
          .dispatch('app-users/fetchData', {
            limit: perPage.value,
            offset:offset,
            keywords: searchQuery.value,
            
              /* page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            status: statusFilter.value,*/
          })
          .then(response => {
           
            const  groups  = response.data.data
         
            const total = response.data.total;
            callback(groups)
            totalGroups.value = total
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching invoices' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }
    const updateUser = (data)=>{

      userda.value = data;
      isUserFormSidebarActive.value = true;
    }
    const deleteData = (id) => {
      //fetchDeleteProperty
      store.dispatch('app-users/fetchDeleteUser',id).then(response=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Delete Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        refetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Delete User",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })
      //console.log('id', id);
      
    }

    const resolveUserStatusVariant = status => {
       
        if (status) return 'success'
        if (!status) return 'secondary'
        return 'primary'
    }
    const resolveUserStatusnameVariant = status => {
     
        if (status) return 'Active'
        if (!status) return 'Inactive'
        return 'primary'
    }
    return {
        isUserFormSidebarActive,
        fetchData,
        tableColumns,
        perPage,
        currentPage,
        totalGroups,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
    
        statusFilter,
        userda,
        deleteData,
   
        updateUser,
        refetchData,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant
    }
}