<template>
    <b-sidebar
      id="group-form-sidebar"
      :visible="isUserFormSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="getData(userData)"
      @hidden="resetForm"
      @change="(val) => $emit('update:is-user-form-sidebar-active', val)"
    >
        <template #default="{ hide }"> 
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
                User Form
            </h5>
    
            <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
            />
    
          </div>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
          <b-form
            class="p-2 form-boder-input"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="Data.email"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Email"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- user name -->
            <validation-provider
              #default="validationContext"
              name="Username"
              rules="required"
            >
              <b-form-group
                label="Username"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  v-model="Data.username"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Username"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Firstname"
              rules="required"
            >
              <b-form-group
                label="First Name"
                label-for="firstname"
              >
                <b-form-input
                  id="firstname"
                  v-model="Data.first_name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="First Name"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Lastname"
              rules="min:0"
            >
              <b-form-group
                label="Last Name"
                label-for="lasttname"
              >
                <b-form-input
                  id="lasttname"
                  v-model="Data.last_name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Last Name"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Phone"
              rules="min:0"
            >
              <b-form-group
                label="Phone"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="Data.phone"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="phone"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- password -->
            <validation-provider
              #default="validationContext"
              name="Password"
              rules="password"
            >
            <label>Password</label>
            <b-input-group
                  class="input-group-merge"
              
                >
                
                <b-form-input
                  id="password"
                  v-model="Data.password"
                  autofocus
                  :type="passwordFieldType"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Password"
                  class="input-group-merge"
                />
                <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-input-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Confirm Password"
              rules="confirmed:Password"
            >
            <label>Confirm Password</label>
            <b-input-group
                  class="input-group-merge"
              
                >
                <b-form-input
                  id="confirm-password"
                  v-model="Data.confirmpassword"
                  :type="confirmpasswordFieldType"
                  autofocus
                  
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Confirm Password"
                />
                <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="confirmpasswordToggleIcon"
                      @click="toggleconfirmPasswordVisibility"
                    />
                  </b-input-group-append>
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-input-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="group_id"
              rules="required"
            >
              <b-form-group
                label="Group"
                label-for="group_id"
              >
        
                <v-select
                id="group_id"
                                v-model="Data.group_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="title"
                                :reduce="(option) => option.id"
                                :options="groupData"
                        />
               
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="branch_id"
              rules="required"
            >
              <b-form-group
                label="Branch"
                label-for="branch_id"
              >
        
                <v-select
                id="branch_id"
                                v-model="Data.branch_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="title"
                                :reduce="(option) => option.id"
                                :options="branchData"
                        />
               
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="IsActive"
              rules="min:0"
            >
              <b-form-group
                label="Is Active"
                label-for="IsActive"
              >
              
                <b-form-checkbox
                id="isactive"
                                checked="true"
                                class="custom-control-success"
                                v-model="Data.is_active"
                                switch
                  />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
          </validation-observer>
          
        
        </template>
        
    </b-sidebar>
</template>
<script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton,BInputGroup,BInputGroupAppend
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref } from '@vue/composition-api'
  import { required, alphaNum, email, password,confirmed } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { useToast } from 'vue-toastification/composition'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
      BFormInvalidFeedback,
      BButton,
      vSelect,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      BInputGroup,
      BInputGroupAppend
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isUserFormSidebarActive',
      event: 'update:is-user-form-sidebar-active',
    },
    mixins: [togglePasswordVisibility],
    props: {
      isUserFormSidebarActive: {
        type: Boolean,
        required: true,
      },
     
      userData: {
        type: Object,
        required: false,
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
        
      }
    },
    computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    confirmpasswordToggleIcon() {
      return this.confirmpasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    
  },
    setup(props, { emit }) {
      const toast = useToast()
      const groupData = ref([]);
      const branchData = ref([]);
      const userStorage = JSON.parse(localStorage.getItem('userData'));
     
      const blankData = {
        created_by:userStorage.id,
        created_at:null,
        email: '',
        username: '',
        first_name:'',
        last_name:'',
        phone:'',
        password: '',
        confirmpassword:'',
        roles: null,
        group_id:'',
        branch_id:'',
        is_active:true
     
      }
  
      const Data = ref(JSON.parse(JSON.stringify(blankData)))
      const resetData = () => {
        Data.value = JSON.parse(JSON.stringify(blankData))
      }
      const getData = (data)=>{
        
        if(data){
          delete data.password;
          data.is_active = data.is_active?true:false;
          Data.value  = JSON.parse(JSON.stringify(data));
        }
       // Data.value  = JSON.parse(JSON.stringify(data))
      
      }
      store.dispatch('app-users/fetchGroupData', {})
      .then(response => {
        groupData.value = response.data.data
      })
      store.dispatch('app-users/fetchBranchData', {})
      .then(response => {
        branchData.value = response.data.data
      })
      
      const onSubmit = () => {
        Data.value.is_active =  Data.value.is_active?1:0;
        if(Data.value.id){
          store.dispatch('app-users/updateUser', Data.value)
          .then((res) => {
            if(res.data.success){
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Success`,
                  icon: 'BellIcon',
                  variant: 'success',
                  text: `${res.data.message}`,
                },
              });
              emit('refetch-data')
              emit('update:is-user-form-sidebar-active', false)
            }else{
             
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: `${res.data.message}`,
                },
              });
        
              
            }
          })

        }else{
         
          
          store.dispatch('app-users/addUser', Data.value)
          .then((res) => {
            
            if(res.data.success){
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Success`,
                  icon: 'BellIcon',
                  variant: 'success',
                  text: `${res.data.message}`,
                },
              });
              emit('refetch-data')
              emit('update:is-user-form-sidebar-active', false)
            }else{
             
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: `${res.data.message}`,
                },
              });
        
              
            }
           
          })

        }
        
      }
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation(resetData)
  
      return {
        Data,
        onSubmit,
        groupData,
        branchData,
        refFormObserver,
        getValidationState,
        resetForm,
        getData
      }
    },
  }
</script>